.file-drop-zone {
  display: flex;
  flex: 1 1 100%;
  height: calc(100% - 150px);
  width: 100%;
  background-color: rgb(10, 14, 17);
  border: 1px dotted white;
  justify-content: center;
  align-items: center;
  color: white;
  margin-top: 2rem;
}

.import-files-container {
font-size: 1.25em;
text-align: center;
.select-link {    
  color: #1b77f7;
}

.file-name {
  font-size: 1em;;
  font-style: italic;
}
}

.ai-file {
font-size: 3em;
}