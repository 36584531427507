.aura-dark-gray-form-container {
  overflow: auto;
}

.aura-dark-footer {
  @at-root .aura-dark-gray-form-container {
    height: calc(100% - 66px) !important;
  }
}

.aura-footer-content {
  width: 780px;
  height: 100%;
  padding: 0;
  margin: 0 auto;
  overflow: auto;

  display: flex;
  flex-direction: row-reverse;

  > * {
    width: 150px;
    border-color: rgba(0,0,0,0.5);
    margin: auto 0 auto 15px;
  }

  button {
    border: 1px solid rgba(0,0,0,0.5);
  }
}

/*
*  Mobile-centric styling
*/

@media (max-width: 991px) {

  .aura-form-input {
    margin: 0;
  }

  .aura-footer-content {
    width: unset;
  }

  .aura-footer-content button {
    width: calc(50% - 15px);
    margin: auto;
  }

  .aura-primary-button {
    width: 45%;
    margin-left: 5px;
  }

  .aura-dark-button {
    width: 45%;
    margin-right: 5px;
  }

  .aura-dark-footer {
    flex-direction: row-reverse;
  } 
}