.aura-alert {
  width: 100%;
  display: flex;
  flex-direction: row;
  text-align: center;
  opacity: 1;
  border-color: 'transparent';
  margin: 0.5em 0;
  border-radius: 5px;
  padding: 0.65em;
}

.aura-alert-icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 0.5em;
}

.aura-alert-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 0.5em;
  flex: 1 1 0;
  align-items: center;
}

.error-message {
  text-align: center;
  align-items: center;
}