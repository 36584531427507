.file-drop-zone {
    display: flex;
    flex: 1 1 100%;
    height: calc(100% - 150px);
    width: 100%;
    background-color: rgb(10, 14, 17);
    border: 1px dotted white;
    justify-content: center;
    align-items: center;
    color: white;
    margin-top: 2rem;
}

.import-files-container {
  font-size: 1.25em;
  text-align: center;
  .select-link {
    color: #1b77f7;
  }

  .file-name {
    font-size: 1em;;
    font-style: italic;
  }
}

.ai-file {
  font-size: 3em;
}

.report-summary {
    color: white;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    margin: 5px 0;
    display: flex;
    flex-direction: column;
    height: calc(100% - 120px);
    padding-top: 20px;
}

.report-success-summary {
    height: 41px;
    border: 1px solid #008800;
    background-color: #232B23;
    display: flex;
    justify-content: start;
    align-content: center;
    text-align: center;
    flex-wrap: wrap;
    padding: 0 10px;
}

.report-error-summary {
  height: 41px;
  border: 1px solid #008800;
  background-color: #232B23;
  display: flex;
  justify-content: start;
  align-content: center;
  text-align: center;
  flex-wrap: wrap;
  padding: 0 10px;
  border: 1px solid #E00000;
  background-color: #570900;
}

.report-error-list {
  border: 1px solid #E00000;
  padding: 0 10px;
  padding-top: 10px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px #F0F0F0;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px #cdcdcd;
  }

  .report-error-item {
    color: #E4E7EB;
    height: 36px;
  }
}

.flat-button {
    width: 180px;
    border: none !important;

    > * {
      vertical-align: middle;
    }
}