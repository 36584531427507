.main-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.main-header {
  display: flex;
  flex-direction: row;
}

.heading-container {
  display: flex;
  flex-direction: column;
  padding: 1rem;

  .main-header h2 {
    color: #000;
  }
  
  .main-header i {
    color: #808080;
  }
  
}

.heading-dark-container {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  height: 1.375rem;
}

.aura-badge {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0.5rem;
  position: relative;
}

.aura-badge label {
  padding: 0.1rem 0.5rem;
  font-size: 1.1rem !important;
  font-weight: 500;
  white-space: nowrap;
  top: 50%;
}

.aura-badge-purple {
  color: #ffffff;
  background-color: #c36dda;
}

.aura-search-wrapper {
  position: relative;
  margin-left: auto;
  height: fit-content;
  margin-top: auto;
  margin-bottom: auto;
}

.helpButtonDesktop {
  background-color: transparent;
  border: none;
}

.helpDesktop {
  height: 1.5rem;
  margin-top: 1.25rem;
  margin-bottom: 1rem;
}

.ai-search {
  position: absolute;
  margin: 10px;
  color: rgb(184, 185, 187);
  background-color: transparent;
  border: none;
}

.ai-x-circle {
  position: absolute;
  top: 50%;
  transform: translate(-24px, -50%);
}

.aura-component-header-buttons .aura-flat-dark-button {
  border-color: #808080;
  color: #808080;
  margin-right: 0.625rem;
  height: 2rem !important;
}

button.aura-flat-dark-button:hover {
  background-color: #808080;
  color: white;
}

.aura-component-header-buttons {
  margin-top: auto;
  margin-bottom: auto;
  padding-right: 1rem;
}

@media (max-width: 992px) {
  input {
    font-family: 'Open Sans', sans-serif !important;
    font-weight: 400;
  }

  .heading-container {
    margin-top: 4.375rem;
  }

  // heading container, inside dark container
  .headerMenuButton {
    top: 1.3rem;
    left: -0.5rem;
    background-color: transparent;
    height: 1rem;
    width: auto;
    border: none;
    z-index: 900;
  }
  
  .headerMenu {
    width: auto;
    height: 100%;
  }
  .menuArrowButton {
    height: 1.4375rem;
    background-color: transparent;
    border: none;
    z-index: 1100;
    border: none;
  }

  .menuArrow {
    height:90%;
    width: 1.125rem;
  }

  .navigation-menu {
    width: 40%;
    height: 100%;
    position: absolute;
    background-color: white;
    border:solid;
    border-color:black;
    margin-left: -12.5rem;
  }

  .search-icon {
    color: rgb(184, 185, 187);
    background-color: transparent;
    border: none;
    font-size: 1.25rem;
  }
  
  // x icon in header
  .ai-x {
    background-color: rgb(32, 37, 42);
    color: white;
    border: none;
    font-size: 1.25rem;
  }
  // microphone icon
  .fdIcon {
    height: 1.5rem;
  }
  // "dictionary" title in header
  .title {
    color: gainsboro;
    font-size: 1.25rem;
    color: gainsboro;
    margin-top: 0.8125rem;
    margin-left: 0.25rem;
  }

  .help-button {
    text-align: left;
    border: none;
  }

  .secondary-menu-button {
    background-color: transparent;
    border: none;
  }

  .threeDots {
    background-color: #333;
    border-color: #808080;

    > * {
      color: white !important;
      width: 100%;
      text-align: left;
    }

    > *:hover {
      color: black !important;
    }
  }

  // search bar in mobile view
  .aura-search-wrapper-mobile {
    width: 100%;
    background-color: #1c2127;
    text-align: center;
    padding-bottom: 0.3125rem;
    position: relative;

    // search icon in search bar
    .ai-search-mobile { 
      color: rgb(140, 145, 152);
      height: 0.625rem;
      top: 0.5rem;
      @media (min-width: 768px) {
        left: 1.8rem;
      }
      left: 1.2rem;
      position: absolute;
      background-color: transparent;
      font-size: 1.25rem;
      border: none;
    }
    // x icon in search bar
    .ai-x-circle { 
      top: 1.25rem;
      right: -0.3125rem;
      position: absolute;
    }
  }
  
  // main header container
  .heading-dark-container {
    display: flex;
    flex-direction: row;
    padding: 1rem 0;
    height: 1.375rem;
    background-color: rgb(32, 37, 42);
  }

  .aura-component-header-buttons {
    display: flex;
    position:absolute;
    z-index: 5000;
    top: 2.4rem;
    right: -1.25rem;
    flex-flow: column;
    border-radius: 0.3125rem;
    border: solid;
    background: gray;
  }

  .secondary-menu-items {
    text-align: left;
    border: none;
  }
}

@media (max-width: 325px) {
  .title {
    font-size: 0.825rem;
    color: gainsboro;
  }
}