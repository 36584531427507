.left-nav-adjustment {
  height: 100% !important;
  width: 242px;
  min-width: 242px;
  user-select: none;
}

.link-adjustment {
  text-decoration: none;
  padding-left: 15px;
  width: 100%;
  margin: auto 0;
}

.aura-category-list-item-adjustment {
  > * {
    color: white;
  }

  &:hover {
    > * {
      color: black;
    }
  }
}

.display-name-text {
  color: white;
  margin-left: 15px;
  font-size: 13px;
  max-width: 182px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  z-index: 1300;
}

@media (max-width: 991px) {
  .left-nav-adjustment {
    user-select: none;
    position: absolute;
    z-index: 1050;
    overflow: visible;
  }
}
