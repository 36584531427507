.sign-in-container {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: rgb(20, 24, 28);
}

.sign-in-content {
  width: 410px;
  height: fit-content;
  margin: auto;
  position: absolute;
  top: -3em;
  left: 0;
  bottom: 0;
  right: 0;
  img.logo {
    margin: 20px 10px;
    position: absolute;
    margin-top: -5em;
  }

  .login-form {
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #73777d;
    border-radius: 4px;
    background-color: #242a30;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);

    .form-entry {
      margin: 10px 20px;
      input {
        width: 100%;
        height: 32px;
      }
    }

    .form-checkbox-entry {
      margin: 10px 20px;
    }

    .form-entry-link {
      margin: 10px;
      a {
        color: #2091ff;
        font-size: 14px;
        text-align: center;
      }
    }

    label {
      color: #9ea2a8;
      font-size: 14px;
      display: inline-block;
      padding-bottom: 3px;
    }

    button {
      width: 100%;
      height: 32px;
      font-size: 1.1em;
      border-color: transparent;
    }
  }
}

.aura-form-error-message-container {
  display: block;
  height: 74px;
  margin-top: 20px;
}

.auto-signin-container {
  left: 50%;
  padding: 20px;
  position: absolute;
  @media (min-width: 601px) {
    top: 100%;
  }
  @media (max-width: 600px) {
    top: 50%;
  }
  transform: translate(-50%, -50%);
  text-align: center;
  width: fit-content;
}

.auto-signin-logo,
.auto-signin-loader,
.auto-signin-text {
  display: flex;
  margin: auto;
}

.auto-signin-logo,
.auto-signin-loader {
  margin-bottom: 50px;
}

.auto-signin-text {
  color: #5e6368;
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 22px;
  letter-spacing: 0;
  text-align: center;
}

.login-warning-title {
  color: white;
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 24px;
  letter-spacing: 0;
}


.login-warning-message {
  color: white;
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 19px;
  letter-spacing: 0;
}

@media only screen and (max-width: 600px) {
  .auto-signin-logo {
    width: 280px;
  }

  @media (max-width: 900px) {
    .auto-signin-loader {
      margin-top: 5em;
    }

    .auto-signin-text {
      margin-bottom: -40px;
      margin-top: 10;
    }

    .sign-in-container {
      position: relative;
      width: 100%;
      height: 100vh;
      background-color: rgb(20, 24, 28);
    }

    .sign-in-content {
      max-width: 330px;
      height: fit-content;
      margin-top: 1em;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      img.logo {
        margin: 20px 10px;
        position: relative;
        margin-top: 0.25em;
        max-width: 290px;
      }

      .login-form {
        width: 100%;
        box-sizing: border-box;
        border: 1px solid #73777d;
        border-radius: 4px;
        background-color: #242a30;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);

        .form-entry {
          margin: 10px 20px;
          input {
            width: 100%;
            height: 32px;
          }
        }

        .form-checkbox-entry {
          margin: 10px 20px;
        }

        .form-entry-link {
          margin: 10px;
          a {
            color: #2091ff;
            font-size: 14px;
            text-align: center;
          }
        }

        label {
          color: #9ea2a8;
          font-size: 14px;
          display: inline-block;
          padding-bottom: 3px;
        }

        button {
          width: 100%;
          height: 32px;
          font-size: 1.1em;
          border-color: transparent;
        }
      }
    }

    .aura-form-error-message-container {
      display: block;
      height: 74px;
      margin-top: 20px;
    }
  }
}
