.activity-layer-container {
  position: relative;
  height: 100%;
  width: 100%;
}

.activity-layer-indicator-container + * {
  filter: blur(1px);
}

.activity-layer-indicator-container {
  position: absolute;
  background-color: white;
  opacity: 0.7;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: table;
  z-index: 999;
}

.overflow {
  overflow: auto;
}

.icon-container {
  position: relative;
  vertical-align: middle;
  display: table-cell;
  text-align: center;
}
