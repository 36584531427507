.auto-signin-container {
  left: 50%;
  padding: 20px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.auto-signin-logo, .auto-signin-loader, .auto-signin-text {
  display: flex;
  margin: auto;
}

.auto-signin-logo, .auto-signin-loader {
  margin-bottom: 50px;
}

.auto-signin-text {
  color: #5E6368;
  font-family: 'Open Sans', Arial, sans-serif;
  font-size: 22px;
  letter-spacing: 0;
  text-align: center;
}

@media only screen and (max-width: 600px) {
  .auto-signin-logo {
    width: 280px;
  }
}