.headerDisplay {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: flex-end;

  h2 {
    color: white;
    @media (max-width: 1000px) {
      font-size: 25px;
    }
  }
} 

.item {
  align-self: flex-start;
}