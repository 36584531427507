.aura-category-list {
  width: 250px;
  box-shadow: inset -1px 0 0 0 #e6e6e6;
  background-color: #ffffff;
  height: 100%;
  overflow: visible;
  user-select: none;
}

.aura-category-list > h5 {
  margin-left: 15px;
}

.aura-content-container, .aura-left-container {
  margin-left: 25px !important;
  width: calc(100% - 50px);
}

.aura-mobile-menu {
  display: none;
}

.aura-mobile-dropdown {
  display: none;
}

.aura-badge {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.aura-badge label {
  padding: 0.1em 0.5em;
  font-size: 1.1rem !important;
  font-weight: 500;
}

.aura-badge-purple {
  color: #ffffff;
  background-color: #c36dda;
}


/* Small-screen */
@media (max-width: 991px) {
  .aura-category-list {
    display: none !important;
  }

  .aura-mobile-menu {
    background-color: #000000;
    display: flex;
    flex-direction: row;
    height: 50px;
  }

  .aura-mobile-menu > * {
    color: #ffffff;
    background-color: #000000;
    margin: auto 0;
  }

   p {
    font-size: 19px;
    color: rgb(207, 208, 211);
    margin-top: 13px;
    margin-left: 4px;
  }

  .down-chevron {
    border-style: solid;
    color: rgb(207, 208, 211);
    border-width: 2px 2px 0 0;
    display: inline-block;
    position: relative;
    height: 0.45em;
    width: 0.45em;
    transform: rotate(135deg);
    padding: 1px;
    margin-left: 6px;
    margin-top: 17px;
  }

  .aura-mobile-menu > .aura-flat-button {
    border-width: 3px;
    height: 31px;
    width: 31px;
    margin-left: auto;
    margin-right: 15px;
    font-size: 17px;
    padding: 4px;
  }

  .aura-mobile-dropdown {
    position: absolute;
    max-height: calc(100% - 70px);
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 1000;
    margin-top: -8px;
    margin-bottom: 5px;
    margin-left: 10px;
    border-radius: 7px; 
    background-color: white;
    box-shadow: 0px 0px 5px black;
    max-height: 300px;
    width: 70%;
  }
  
  .aura-mobile-dropdown .aura-category-list-item {
    background-color: #000000;
    color: #FFFFFF;
  }

  .search-results-header {
    position: absolute; 
    background-color: blue;
  }

  .aura-mobile-menu > .aura-add-button {
    display: flex;
    flex-direction: row;
    margin-top: 1.1em;
    position:absolute;
    right: 0.5em;
    border: none;
    width: fit-content;
    height: auto;
    padding-right: 5px;
    padding-left: 5px;
  }

  .aura-mobile-button {
    background-color: #000000;
    display: flex;
    flex-direction: row;
    position: relative;
    height: 50px;
    border: none;
  }

}
