.notification-container {
  // 1000 because activity indicators are 999
  z-index: 1000;
  position: absolute;
  right: 10px;
  bottom: 10px;
  height: fit-content;
  width: fit-content;
  display: flex;
  flex-direction: column;
  user-select: none;
  pointer-events: none;

  > * {
    margin-bottom: 10px;
    pointer-events: all;
  }
}
