body {
  margin: 0;
  /* min-width needs to become a result of a query param */
  /* min-width: 1020px; */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html::-webkit-scrollbar {
  display: none;
}

html {
  scrollbar-width: none;
}

html * {
  scrollbar-width: unset;
}

/* Standardize what the scrollbar looks like across browsers and OS */
/* body *::-webkit-scrollbar {
  -webkit-appearance: none;
}

body *::-webkit-scrollbar:vertical {
  width: 16px;
}

body *::-webkit-scrollbar:horizontal {
  height: 16px;
}


body *::-webkit-scrollbar-thumb { */
  /*border-radius: 0px; /* 8px to match macOS */
  /*border: 2px solid white; /* border should match background, can't be transparent */
  /* background-color: rgba(0, 0, 0, .5);
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* Reset */
textarea[disabled] {
  color: #808080;
  cursor: default;
  background-color: white;
}

input[disabled] {
  color: #808080;
  background-color: white;
}