@import '../../style-dictionary.scss';

.card {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex-shrink: 1;
  width: fit-content;
  margin-left: auto;

  > * {
    vertical-align: middle;
    margin-right: 10px;
  }

  > *:first-child {
    margin-left: 10px;
  }
}

.card.confirmation {
  border: 1px solid #008800;
  border-radius: 4px;
  background-color: #D9EDD9;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.25);
}

.card.information {
  border: 1px solid #0076D5;
  border-radius: 4px;
  background-color: #DEE8FF;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.25);
}

.card.warning {
  border: 1px solid #BC5800;
  border-radius: 4px;
  background-color: #FFE1D8;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.25);
}

.card.error {
  border: 1px solid #EB0100;
  border-radius: 4px;
  background-color: #FFE1E0;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.25);
}

.icon {
  height: fit-content;
  margin-top: 10px;
}

.icon.vertical-center {
  margin-top: auto;
  margin-bottom: auto;
}

.body {
  margin-top: 6px;

  > *:not(:first-child) {
    margin-top: 6px;
  }

  > *:last-child {
    margin-bottom: 6px;
  }
}

.title {
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 18px;
}

.detail {
  font-size: 13px;
  letter-spacing: 0;
  line-height: 18px;
}

.action {
  color: #2568B4;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 18px;
  cursor: pointer;
  text-decoration: underline;
}

.action.vertical-center {
  margin-top: auto;
  margin-bottom: auto;
}

.delete-button {
  border: none;
  background: none;
  cursor: pointer;
  width: fit-content;
  height: fit-content;
  padding: 0;
  margin-top: 10px;
  outline: none;
}
